import React, { useEffect, useContext, useRef } from 'react';
import { Wrapper } from './PetauriBanner.styles'
import BottomLine from '../../BottomLine/BottomLine'

import bgvortex from "../../../images/bg-vortex.jpg"

import {GlobalStateContext, GlobalDispatchContext } from "../../../../src/context/GlobalContextProvider"


const PetauriBanner = () => {
   
    const cta = useRef();

    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)
      
    useEffect(() => {
        cta.current.addEventListener('click', (e)=>{
            e.preventDefault();
            openInterstitial(e.currentTarget.href)
        })  
    },[]); 

    const openInterstitial = (link)=> {
        dispatch({
            type: 'MODAL_CONTENT',
            payload: {
                type: 'INTERSTITIAL',
                link: link
            }
        })
    }
    
    return(
        <Wrapper>
            <div className='banner-wrap'>

                <img src={bgvortex} className='bgvortex'/>

                <div className='content-frame'>
                    <h2>
                        We're excited to announce that TKG
                        is now Powered by Petauri<sup>&trade;</sup>
                    </h2>

                    <div className='white-box'>
                        <p>A purpose-built pharmaceutical-services platform, Petauri is actively partnering with best-in-class companies recognized for their expertise in global market access, health systems, medical affairs, HEOR, patient services, and data and analytics.</p>
                        <p>As part of Petauri, TKG will offer broader expertise to seamlessly support our valued clients with impactful solutions that speed patient access to life-changing care.</p>
                    </div>

                    <a ref={cta} className='cta' href="https://petaurihealth.com/" target='_blank' rel="noopener noreferrer">LEARN MORE ABOUT PETAURI</a>
                </div>
            </div>
            <BottomLine/>
        </Wrapper>
    )
}

export default PetauriBanner
