import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`relative block`}

  .banner-wrap{
    ${tw`
      w-full
      text-white text-center
      flex flex-col items-center justify-start sm:justify-center overflow-hidden
    `}

    

    .bgvortex{
      object-fit: cover;

      ${tw`
        absolute w-full h-full
      `}
    }
    
    .content-frame{
      ${tw`
        w-full mx-auto relative text-left sm:text-center 
        px-4 sm:px-8 lg:px-4
        py-4 sm:py-8 lg:py-4
      `}
      max-width: 940px;

      h2{
        ${tw`text-2xl sm:text-4xl mx-auto`}
        max-width: 32rem;
        text-shadow: 2px 2px 16px #000000;

        sup{
          ${tw`align-baseline`}
        }
      }

      .white-box{
        ${tw`
          bg-white px-4 sm:px-16 py-2 sm:py-6
        `}

        p{
          ${tw`text-black text-lg font-semibold`}
        }
      }
    }

    .cta{
      ${tw`
        bg-lightorange text-white hover:bg-white hover:text-lightorange hover:shadow   transition-all
        block text-center  rounded-full font-bold no-underline
        px-8 py-1 mt-8 sm:mt-14 mb-8 sm:mb-12 mx-auto
      `}
      width: fit-content;
    }
  }
`